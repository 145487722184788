import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { solveMessage } from "../utils";

const AddContract = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [initialDate, setInitialDate] = useState("");
  const [company_id, setCompany_id] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [lote, setLote] = useState("");
  const [refCod, setRefCod] = useState("");
  const [gestor, setGestor] = useState("");
  const [companies, setCompanies] = useState([]);
  const { user } = useAuth();
  const origin = "Criar";
  const navigate = useNavigate();
  const labelStyle = { marginBottom: "-1px" };
  const [load, setLoad] = useState(false);
  const [users, setUsers] = useState([]);
  const toastStyle = {fontSize: '13.3px'};

  const getUsers = async () => {
    const res = await axios.get(`${url}/users`, {
      headers: { authorization: `Bearer ${user.access_token}` },
    });
    if (res.status === 200) {
      setUsers(res.data);
    }
  };

  useEffect(() => {
    getUsers();
  }, [url, user.access_token]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/company`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });
        setCompanies(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [url, user.access_token, setCompanies]);

  const saveConctract = async (e) => {
    e.preventDefault();

    if (finalDate < initialDate) {
      toast.error("Data final não Pode ser inferior  a inicial");
      return;
    }

    setLoad(true);
    try {
      const response = await axios.post(
        `${url}/contract`,
        {
          initialDate: initialDate,
          finalDate: finalDate,
          company: company_id,
          lote: lote,
          codReferencia: refCod,
          gestor: gestor,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false)
      toast.success('Adicionado com Sucesso', {style: toastStyle});
      navigate(`/addRoadContract/${response.data.id}`);
    } catch (error) {
      setLoad(false)
      toast.error(solveMessage(error), {style: toastStyle});
    }
  };

  return (
    <div className="container-sm pt-4 text-black">
      <div className="mx-auto w-75">
        <p className="small">
          <Link to="/" className="mutec text-decoration-none ic">
            Home »{" "}
          </Link>
          <Link to="/listContract" className="mutec text-decoration-none ic">
            Contrato »{" "}
          </Link>
          <span className="ic">Criar Contrato</span>
        </p>
        <h5 className="mb-3 ic" style={{ fontFamily: "Roboto" }}>
          Criar Contrato
        </h5>

        <form
          className=" p-3 shadow-lg bg-white smollText"
          onSubmit={saveConctract}
          style={{
            paddingBottom: "35px",
            paddingLeft: "12px",
            paddingRight: "12px",
          }}
        >
          <div className="row g-3">
              <div className="col-md-3">
                <label for="company_id" className="form-label" style={labelStyle}>
                  Empresa
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <select
                  id="company_id"
                  className="form-select form-select-sm"
                  value={company_id}
                  onChange={(e) => setCompany_id(e.target.value)}
                >
                  <option value=""></option>
                  {companies.map((company) => (
                    <option value={company.id}>{company.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
              <label htmlFor="lote" className="form-label labeFormMargin">
                  
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="lote"
                type="text"
                placeholder=""
                value={refCod}
                onChange={(e) => setRefCod(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="lote" className="form-label labeFormMargin">
                Lote
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="lote"
                type="text"
                placeholder=""
                value={lote}
                onChange={(e) => setLote(e.target.value)}
              />
            </div>

            <div className="col-md-3">
              <label
                for="initialDate"
                className="form-label"
                style={labelStyle}
              >
                Data Inicial
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                name="date"
                type="date"
                className="form-control form-control-sm"
                placeholder=""
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </div>

            <div className="col-md-3">
              <label for="finalDate" className="form-label" style={labelStyle}>
                Data Final
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
    name="date"
    type="date"
    className="form-control form-control-sm"
    id="finalDate"
    placeholder=""
    value={finalDate}
    onChange={(e) => setFinalDate(e.target.value)}
/>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md-6">
              <label htmlFor="gestor" className="form-label labeFormMargin">
                Gestor
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="gestor"
                className="form-select form-select-sm"
                value={gestor}
                onChange={(e) => setGestor(e.target.value)}
              >
                <option value=""></option>
                {users.map((user) => (
                  <option value={user.id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6"></div>
          </div>

          <div className="clearfix mt-3">
            <button className="btn btn-sm float-end text-light sidItemL2">
              {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>
            <Link
              to="/listContract"
              type="button"
              className="btn btn-sm px-3 float-end borderbp ic2 me-2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddContract;
