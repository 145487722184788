import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import List from "../List";
import { formatDate, formatDateTime } from "../utils";
import { toast } from "react-toastify";
import debounce from "lodash.debounce"; // Importa debounce para otimizar chamadas

const ListContract = () => {
    const url = process.env.REACT_APP_BACKEND_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [contracts, setContracts] = useState([]);
    const { user } = useAuth();
  
    const toastStyle = { fontSize: "13.3px" };

    const columnTitles = [
        { key: "created_at", value: "Data Criação" },
        { key: "initialDate", value: "Data Inicial" },
        { key: "finalDate", value: "Data Final" },
        { key: "name", value: "Nome" },
        { key: "codReferencia", value: "Contrato" },
        { key: "contratType", value: "Fonte/Utilizador" },
    ];

    // Busca todos os contratos
    const getContracts = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${url}/contract/all`, {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            });

            // Formata os dados recebidos
            const data = response.data.map((v) => ({
                ...v,
                created_at: formatDateTime(v.created_at),
                initialDate: formatDate(v.initialDate),
                finalDate: formatDate(v.finalDate),
                company: v.company.name,
                contratType: v.company?.isExternalCompany ? "Externo" : "Interno",
                name: v.name.length > 60 ? v.name.slice(0, 50) + "..." : v.name,
            }));

            // Ordena por data de criação
            data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            setContracts(data);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao buscar contratos", { style: toastStyle });
        } finally {
            setLoading(false);
        }
    }, [url, user.access_token]);

    // Busca contratos filtrados no endpoint /contract/search
    const fetchFilteredContracts = async (searchTerm) => {
        if (!searchTerm) {
            getContracts(); // Se a busca estiver vazia, recarrega todos os contratos
            return;
        }
    
        try {
            setLoading(true);
            const response = await axios.post(
                `${url}/contract/search`, 
                { search: searchTerm }, // Envia o termo de busca no corpo da requisição
                {
                    headers: {
                        Authorization: "Bearer " + user.access_token,
                    },
                }
            );
    
            // Formata os dados retornados
            const data = response.data.map((v) => ({
                ...v,
                created_at: formatDateTime(v.created_at),
                initialDate: formatDate(v.initialDate),
                finalDate: formatDate(v.finalDate),
                company: v.company.name,
                contratType: v.company?.isExternalCompany ? "Externo" : "Interno",
                name: v.name.length > 60 ? v.name.slice(0, 50) + "..." : v.name,
            }));
    
            setContracts(data);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao buscar contratos filtrados", { style: toastStyle });
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        getContracts();
    }, [getContracts]);

    const deleteContract = async (id) => {
        try {
            await axios.delete(`${url}/contract/${id}`, {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            });
            toast.success("Eliminado com sucesso", { style: toastStyle });
            await getContracts();
        } catch (error) {
            console.log(error);
            toast.error("Erro ao eliminar", { style: toastStyle });
        }
    };

    // Filtragem com debounce para evitar chamadas excessivas à API
    const filter = debounce((e) => {
        const searchTerm = e.target.value.trim();
        fetchFilteredContracts(searchTerm);
    }, 500); // Aguarda 500ms antes de fazer a requisição

    return (
        <List
            title="Contrato"
            columnTitles={columnTitles}
            items={contracts}
            filter={filter}
            originLink="/listContract#"
            newLink="/addContract"
            viewBaseLink="/viewContract"
            editBaseLink="/addRoadContract"
            deleteItem={deleteContract}
            loading={loading}
        />
    );
};

export default ListContract;
