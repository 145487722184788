import { Marker, Popup } from "react-leaflet";

export const EvaluationLayer = ({
  evaluationPoints,
  setEvaluationPoints,
  editable = false,
}) => {
  const toPoint = (p) => ({
    id: p.id,
    position: [p.lat, p.long],
    evalId: p.evaluation.id,
    score: p.evaluation.ponderacao,
    service: p.evaluation.typeservice.name,
  });

  const handleDrag = (e, id) => {
    const points = (evaluationPoints && [...evaluationPoints]) || [];
    const point = points.find((p) => p.id === id);

    const newPosition = e.target.getLatLng();
    point.lat = newPosition.lat;
    point.long = newPosition.lng;
    setEvaluationPoints(points);
  };

  return (
    <>
      {evaluationPoints &&
        evaluationPoints.map(toPoint).map((point) => (
          <Marker
            draggable={editable}
            eventHandlers={{
              dragend: (e) => {
                handleDrag(e, point?.id);
              },
            }}
            position={point?.position}
          >
            <Popup>
              <strong>Serviço:</strong> {point?.service}
              <br />
              <strong>Ponderação:</strong> {point?.score}
            </Popup>
          </Marker>
        ))}
    </>
  );
};
