import { inconformPerc, conformPerc, inconformCount, solveMessage } from "../utils.js";
import { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { Map } from "../mapa/Mapa.js";
import axios from "axios";
import {toast } from 'react-toastify';
import EditEvaluation from "./EditEvaluation.js";

const ViewEvaluation = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [contractName, setContractName] = useState("");
  const [contractID, setContractID] = useState("");

  const [evaluation, setEvaluation] = useState([]);
  const [roadCodigo, setRoadCodigo] = useState("");
  const [roadExtens, setRoadExtens] = useState("");
  const [positions, setPositions] = useState([]);
  const [tipServic, setTipServic] = useState("");
  const [categoria, setCategoria] = useState("");
  const [userName, setUserName] = useState("");
  const [positId, setPositId] = useState();
  const [section, setSection] = useState([]);
  const [openEditEvaluation, setOpenEditEvaluation] = useState(false);
  const { id } = useParams();
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const tableHeadColor = {color: '#6080A3'}
  const [edited, setEdited] = useState(false);
  const [points, setPoints] = useState([]);
  const getPoints = async () => {
    const res1 = await axios.get(`${url}/evaluation/${id}/positions`, {
      headers: { authorization: `Bearer ${user.access_token}` },
    });
    if (res1.status === 200) setPoints(res1.data);
  };

  const changePoints = (data) => {
    setPositions(data);
    setEdited(true);
  };
 
  const handleSave = async () => {
    await axios.put(
      `${url}/evaluation/${id}/positions`,
      { positions },
      {
        headers: { authorization: `Bearer ${user.access_token}` },
      }
    );
    
    setEdited(false);
  };
  const getUsers = async () => {
    const res = await axios.get(`${url}/users`, {
      headers: { authorization: `Bearer ${user.access_token}` },
    });
    if (res.status === 200) {
      setUsers(res.data);
    }
  };


  const getEvaluationPositions = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/evaluation/byId/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });

      const evaluationData = response.data[0];
      setEvaluation(evaluationData);
      setSection(evaluationData.section);
      setTipServic(evaluationData.typeservice.name);
      setCategoria(evaluationData.criterion.name);
      setPositions(evaluationData.positions);
      setContractID(evaluationData.section.contract.id);
      setContractName(evaluationData.section.contract.name);
      setRoadCodigo(evaluationData.section.road.codigo);
      setRoadExtens(evaluationData.section.road.extensao);

      // Obtém o ID do gestor diretamente
      const gestorId = evaluationData.section.contract.gestor;
      

      // Busca o nome correspondente ao gestor
      const foundUser = users.find((user) => user.kId === gestorId);
console.log(gestorId ,"found");
      setUserName(
        foundUser
            ? `${foundUser.firstName} ${foundUser.lastName}`
            : ""
    );
  } catch (error) {
      console.log("Erro ao buscar avaliação:", solveMessage(error));
    } 
  })
 


  useEffect(() => {
    getUsers();
    getEvaluationPositions()
    getPoints()
    
  }, [url, id, user.access_token]);



  const openModal = async (positionId) => {
    setPositId(positionId)
    setOpenEditEvaluation(true)
  }

  const closeModal = async () => {
    setOpenEditEvaluation(false)
    getEvaluationPositions()
  }

  const deleteEvaluation = async (positionId) => {
    try {
      await axios.delete(
        `${url}/position/${positionId}`,
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      toast.success('Deletado', {style: {fontSize: '13.3px'}})
      getEvaluationPositions()
    } catch (error) {
      console.log(solveMessage(error));
    }
  }
 
  const renderImage = async (image) => {
    var img = `<img src='${url}/image/streamFile/${image}' />`;
    var poupup = window.open();
    poupup.document.write(img);
  };
  
  return (

    
    <div className="container-sm pt-4 ">
      <p className="small">
        <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
        <Link to='/listContract' className="mutec text-decoration-none ic">Contrato » </Link>
        <Link to={"/viewContract/"+contractID} className="mutec text-decoration-none ic">
        Visualizar Contrato » {" "}
        </Link>
        {/* <Link to={"/viewContract/"+contractID} className="mutec text-decoration-none ic">
        Avaliaçãoes »{" "}
        </Link> */}
        <span className="ic">Ver Avaliação</span>
      </p>
   
     <div className="w-80 p-4 mx-auto"> 
      <h5 className="mb-2 clearfix" style={{ fontFamily: "Roboto"}}>
       <span className="fw-lighter">Avaliação
          <small className="text-muted ms-2">
            {positions.length > 0 && positions[0].phase}º Mez
          </small>
        </span>
        <span className="fw-lighter ms-2">Contrato
          <small className="text-muted ms-2">
            {contractName}
          </small>
        </span>
        <span className="fw-lighter ms-1">
            Estrada
            <small className="text-muted ms-2">
             {roadCodigo}/{roadExtens}Km
            </small>
        </span>
        <span className="fw-lighter ms-1">
            Troço
            <small className="text-muted ms-2">
             {section.kmInicial} A {section.KmFinal} Km
            </small>
        </span> 
        
      </h5>
      
      <div className=" pb-3">


      <div className=" shadow-lg bg-white p-3 smollText">
  <thead className="row">
    <tr className="col-3">
      <th style={tableHeadColor}>Código de Estrada</th>
      <th className="text-end" >{roadCodigo}</th>
    </tr>
    <tr  className="col-3">
      <th style={tableHeadColor}>Inspetor</th>
      <th className="text-end">{userName}</th>
    </tr>
    <tr  className="col-3">
      <th style={tableHeadColor}>Serviço</th>
      <th className="text-end">{tipServic}</th>
    </tr>
    <tr  className="col-3">
      <th style={tableHeadColor}>SubServiço</th>
      <th className="text-end">{tipServic !== "Pavimento da Via" ? "" : categoria}</th>
    </tr>
    <tr  className="col-3">
      <th style={tableHeadColor}>Anomalias</th>
      <th className="text-end">
        {inconformCount(positions, section.evaluationParameter) > 0 ? (
          <i className="fas fa-times text-danger"></i>
        ) : (
          <i className="fa fa-check text-success"></i>
        )}
      </th>
    </tr>
    <tr  className="col-3">
      <th style={tableHeadColor}>Km Nao Conforme </th>
      <th className="text-end">{inconformCount(positions, section.evaluationParameter) + "Km"}</th>
    </tr>
    <tr  className="col-3">
      <th style={tableHeadColor}>% Nao Conforme </th>
      <th className="text-end">
        {inconformPerc(
          inconformCount(positions, section.evaluationParameter),
          section.KmFinal,
          section.kmInicial
        ) + " %"}
      </th>
    </tr>
    <tr className="col-3">
      <th style={tableHeadColor}>% Conforme </th>
      <th className="text-end">
        {conformPerc(
          inconformCount(positions, section.evaluationParameter),
          section.KmFinal,
          section.kmInicial
        ) + " %"}
      </th>
    </tr>
    <tr  className="col-3">
      <th style={tableHeadColor}>Coeficiente Ponderação </th>
      <th  className="text-end">
        {inconformCount(positions, section.evaluationParameter) > 0
          ? evaluation.ponderacao
          : 0}
      </th>
    </tr>
    <tr className="col-3" >
      <th style={tableHeadColor}>Data Criação </th>
      <th className="text-end">
        {!evaluation.created_at
          ? ""
          : new Date(evaluation.created_at).getDate() +
            "/" +
            (new Date(evaluation.created_at).getMonth() + 1) +
            "/" +
            new Date(evaluation.created_at).getFullYear() +
            " " +
            new Date(evaluation.created_at).getHours() +
            ":" +
            new Date(evaluation.created_at).getMinutes() +
            ":" +
            new Date(evaluation.created_at).getSeconds()}
      </th>
    </tr>
  </thead>
</div>


        <div className="" >

        {<Map
          evaluationPoints={points}
          setEvaluationPoints={changePoints}
          />}
        
{edited &&<div className="clearfix mt-3">
        <button
            className="btn btn-sm float-end text-light sidItemL2"
            onClick={handleSave}
            >
            Salvar
          </button>
        </div>
}
            </div>
            </div>
        {positions.map((position) => (
           position.status === true &&
          <div
            className="card smollText"
            style={{ marginTop: "-10px", borderColor: '#ecf2f8'}}
          >
            <div
              className="card-header text-white"
              style={{ backgroundColor: "#122C48" }}
            >
                
                <div className="clearfix">
                    <button
                      onClick={() =>
                        deleteEvaluation(position.id)
                      }
                      className="btn fas fa-trash-alt p-2 ic2 smollText float-end mt-1 text-danger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Deletar"
                    ></button>
                   <button
                      className="btn fa fa-edit p-2 ic2 smollText float-end text-white mt-1"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Editar" 
                      onClick= {() => openModal(position.id)}
                    ></button>
                    
                  <span>
                    {position.startPosition}   A  {position.endPosition} Km
                  </span>
                  <i className={ position.isConform === true ? 
                        "fa fa-check text-success ms-2 mt-3" : 
                        "fas fa-times text-danger ms-2 mt-3"}>
                  </i>
                </div>
            </div>
            <div className="card-body" style={{marginTop: '-12px'}}>
              <div className="row mx-auto ">
                {position.length === 0
                  ? ""
                  : position.images.map((image) => (
                      image.status === false ? '' :
                      <div className="col-sm-4 p-1 ">
                        <img
                          src={`${url}/image/streamFile/${image.filename}`}
                          style={{ width: "100%" }}
                          onClick={() => renderImage(image.filename)}
                          alt=""
                        />
                      </div>
                    ))}
              </div>
            </div>
          </div>
        ))}
        <EditEvaluation
            isOpen={openEditEvaluation}
            positionId={positId}
            closeEditEval={closeModal}
        ></EditEvaluation>
    </div>
    </div>
  );
};

export default ViewEvaluation;
