import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import { toast } from "react-toastify";
import { solveMessage } from "../utils";

const BACKGROUND_STYLE = {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: 'rgb(0,0,0, 0.7)',
    zIndex: '1000',
    overflowY: "auto"
};

const MODAL_STYLE = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    backgroundColor: '#fff',
    color: 'black',
    width: '450px',
    maxHeight: 'fit-content'
};

const toastStyle = { fontSize: '13.3px' };

export default function ModalAddRoad({ isOpen, roads, contractId, setModalAddRoadClose, getSaved, isExternalContract }) {
    const url = process.env.REACT_APP_BACKEND_BASE_URL;
    const { user } = useAuth();
    const [road, setRoad] = useState("");
    const [filteredRoads, setFilteredRoads] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [load, setLoad] = useState(false);

    const inputRef = useRef(null); // Ref para o campo de entrada
    const dropdownRef = useRef(null); // Ref para a lista dropdown

    // Fechar o dropdown ao clicar fora
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false); // Fechar dropdown se clicar fora
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setRoad(value);

        if (value.length > 0) {
            const filtered = roads.filter((road) =>
                road.codigo.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredRoads(filtered);
        } else {
            setFilteredRoads(roads); // Exibir todas as estradas se o campo estiver vazio
        }
        setShowDropdown(true);
    };

    const handleFocus = () => {
        setFilteredRoads(roads); // Exibir todas as estradas ao focar
        setShowDropdown(true);
    };

    const handleSelectRoad = (selectedRoad) => {
        setRoad(selectedRoad.codigo);
        setFilteredRoads([]);
        setShowDropdown(false); // Fechar o dropdown após a seleção
    };

    const save = async (e) => {
        e.preventDefault();
        setLoad(true);

        const selectedRoad = roads.find((r) => r.codigo === road);
        if (!selectedRoad) {
            toast.error("Estrada inválida", { style: toastStyle });
            setLoad(false);
            return;
        }

        try {
            await axios.post(
                `${url}/contract/sectCont`,
                {
                    contractId: Number(contractId),
                    roadId: Number(selectedRoad.id),
                    isExternalContract: !!isExternalContract
                },
                {
                    headers: {
                        Authorization: "Bearer " + user.access_token,
                    },
                }
            );
            setLoad(false);
            toast.success("Adicionado com Sucesso", { style: toastStyle });
            getSaved();
        } catch (error) {
            setLoad(false);
            toast.error(solveMessage(error), { style: toastStyle });
        }
    };

    if (isOpen) {
        return (
            <div style={BACKGROUND_STYLE}>
                <div id="modalContainer" style={MODAL_STYLE}>
                    <h5 className="ms-3" style={{ fontFamily: "Roboto" }}>
                        Adicionar Estrada
                    </h5>
                    <form onSubmit={save}>
                        <div className="card shadow-lg m-3 smollText">
                            <div className="card-body">
                                <label htmlFor="road_cod" className="form-label">
                                    Código
                                    <span className="text-danger" style={{ fontSize: "22px" }}>*</span>
                                </label>
                                <input
                                    ref={inputRef} // Ref para o campo de entrada
                                    type="text"
                                    id="road_cod"
                                    className="form-control"
                                    value={road}
                                    onChange={handleInputChange}
                                    onFocus={handleFocus} // Modificado para exibir a lista completa
                                    placeholder="Digite para buscar..."
                                    autoComplete="off"
                                />
                                {showDropdown && filteredRoads.length > 0 && (
                                    <ul ref={dropdownRef} className="list-group mt-1" style={{ maxHeight: "150px", overflowY: "auto", position: "absolute", zIndex: 10, width: "90%" }}>
                                        {filteredRoads.map((r) => (
                                            <li
                                                key={r.id}
                                                className="list-group-item list-group-item-action"
                                                onClick={() => handleSelectRoad(r)} // Fechar dropdown após seleção
                                                style={{ cursor: "pointer" }}
                                            >
                                                {r.codigo}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        <div className="clearfix me-3">
                            <button className="btn btn-sm ms-1 float-end text-light sidItemL2">
                                {load ? <span className="spinner-border spinner-border-sm"></span> : "Salvar"}
                            </button>

                            <button
                                type="button"
                                className="btn btn-sm px-3 float-end borderbp ic2"
                                onClick={setModalAddRoadClose}
                            >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    return null;
}
